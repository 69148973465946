// Background colours
$backgroundColour: #ffffff !default;
$codeBackgroundColour: #fafafa !default;
$featureBackgroundColour: #f9fafa !default;
$accentColour: #05bf85 !default;

// Text colours
$headingColour: #242e2b !default;
$bodyColour: #555555 !default;
$linkColour: #05bf85 !default;
$hoverColour: #008000 !default;
$focusColour: #fa407a !default;
$captionColour: #a8adac !default;
$white: #ffffff !default;

// Typography
$bodytype: (
  font-family: "Georgia, serif",
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
) !default;

$headingtype: (
  font-family: '"Merriweather", serif',
  regular: 400,
  bold: 700,
  cap-height: 0.75
) !default;

$monospacetype: (
  font-family: "Menlo, monospace",
  regular: 400,
  cap-height: 0.68
) !default;

// Font import, if you're using a non-standard web font
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,700");
