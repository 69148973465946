// Structural elements
body {
  background: $backgroundColour;
  color: $bodyColour;
  height: 100%;
  display: flex;
  @include flex-direction(column);
  overflow-x: hidden;
  font-family: "Georgia serif";
  font-size:18px;
}
p{
  // font-family: "Georgia serif";
  font-size:18px;
  color: $bodyColour;
}

header a{
  color:$white;
  padding:5px 0;
}
header li.item--current a{
  color:$white;
  border-bottom: 1px solid rgb(0, 187, 255);
  
}
.mbr-fullscreen {

  background-position-y: unset!important;
  margin-top: 0px!important;
}
.line {
  background-color: #cc2952;
  color: #cc2952;
  align: center;
  height: 2px;
  margin: 0 auto;
}
.google-map {
  height: 25rem;
  position: relative;
}
.nav a.btn, .btn.btn-sm.btn-primary.display-4{
  // border-radius:0.5rem
  border-radius: 1.5rem;
  padding: 11px 15px!important;
}


// Header, feature and footer
.header,
.footer {
  .nav {
    text-align: right;
  }
  .logo {
    @include flex(0 0 auto);
  }
  .container {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    padding: 1rem 0;
    @include breakpoint(break-1) {
      @include align-items(center);
    }
  }
}


.header .nav {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
}

.feature {
  padding-bottom: .4rem;
  margin-bottom: 1.6rem;
  text-align: center;
  background: $featureBackgroundColour;
  background-size: cover;
  background-position: center;
  .container {
    min-height: 35vh;
    max-width: 60%;
    @include flex-direction(column);
    @include justify-content(center);
  }
}

.logo {
  display: inline-block;
  line-height: 0;
  max-height: 4rem;
  @include flexbox;
  @include align-items(center);
  img {
    max-height: 5rem;
  }
}

.nav {
  a {
    padding: .2rem;
  }
  &--social a {
    display: inline-block;
    line-height: 1;
  }
}

.small {
  padding-top: .8rem;
  color: $captionColour;
  display: inline-block;
}

.footer {
  background: $headingColour;
  color: $captionColour;
  a {
    color: $captionColour;
    &:hover {
      color: $backgroundColour;
    }
  }
  .container {
    @include flex-wrap(wrap);
  }
  .small {
    padding-top: 0;
  }
}


// Pagination nav
.nav--paginator {
  @include flexbox;
  @include justify-content(space-between);
  color: $captionColour;
  text-align: center;
}

.pagination {
  min-width: 20%;
}


// Main content
.main {
  @include flexbox;
  @include flex-direction(column);
  @include flex(1, 0, auto);
  @include justify-content(flex-start);
  margin-bottom: 1.6rem;
  @include breakpoint(break-1) {
    @include justify-content(center);
    @include flex-direction(row);
  }
}
.header{
  background-color: #546e93;
}
.header,
.feature,
.footer {
  @include flex(0, 0, auto);
}

.content {
  @include breakpoint(break-1) {
    width: 62%;
  }
}

.share .button {
  margin-right: .3rem;
}

.aside {
  @include breakpoint(break-1) {
    max-width: 34%;
    margin-left: 4%;
    @include flex(1, 0, auto);
    opacity: 0.5;
    transition: opacity .1s;
    .section {
      position: sticky;
      top: 0;
    }
    &--left {
      margin-left: 0;
      margin-right: 4%;
      order: -1;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.section {
  margin: 0;
}


// Lists
.list {
  list-style: none;
  &--nav {
    overflow: hidden;
    margin-bottom: var(--listHeight, 0);
    display:none;
    @include breakpoint(break-1) {
      display: block;
    }
  }
  .item--post,
  .item--result,
  .item--category {
    margin-left: 0;
  }
}

.item {
  &--nav {
    @include breakpoint(0) {
      display: block;
    }
    @include breakpoint(990) {
      margin-left: .4rem;
      display: inline-block;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  &--current {
    a {
      color: $captionColour;
    }
  }
}


// Links, icons and images
a .icon {
  transition: fill .1s;
  &:hover {
    fill: currentColor;
  }
}

.icon {
  vertical-align: middle;
  width: 1.1em;
  height: 1.1em;
  fill: CurrentColor;
}

// Media content
img {
  max-width: 100%;
  height: auto;
}

.figure {
  line-height: 0;
  &--left {
    float: left;
    padding-right: .8rem;
  }
  &--right {
    float: right;
    text-align: right;
    padding-left: .8rem;
  }
  &--center {
    text-align: center;
    clear: both;
  }
}

img.self{
  border: 3px solid #55b4d4 !important
}
.page-image   .mbr-figure{
  width:35%;
  align-self: center;
}

.page-image   .mbr-figure.normal {
  width:100%;
}
.page-image   .mbr-figure.normal img{
  border: none !important;
  border-radius: 0%!important;
}
.video,
.map {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.map {
  padding-bottom: 70%;
}

.figure,
.video,
.map,
.form {
  margin-bottom: .8rem;
}

audio,
video {
  width: 100%;
}


// Form elements and buttons
.form {
  position: relative;
  &__legend {
    font-style: italic;
    color: $captionColour;
    position: absolute;
    overflow: hidden;
    right: 0;
    clip: rect(0 0 0 0);
  }
}

button,
.button,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="submit"],
input[type="color"],
textarea,
select {
  padding: .6rem 1.2rem;
  margin-bottom: .6rem;
  transition: color .1s, background-color .1s, border .1s;
  line-height: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  .icon {
    margin: 0 0 .35rem;
  }
}

input[type="submit"],
button,
.button {
  cursor: pointer;
  display: inline-block;
  color: $backgroundColour;
  background: $accentColour;
  transition: box-shadow .1s;
  will-change: box-shadow;
  box-shadow: inset 0 0 0 2rem transparent;
  &:hover {
    box-shadow: inset 0 0 0 2rem rgba(0,0,0,0.25);
  }
  &:active,
  &:focus {
    box-shadow: inset 0 0 0 2rem rgba(0,0,0,0.25);
  }
}

.button--nav {
  @include breakpoint(990) {
    display: none;
  }
  background: none;
  color: white;
  margin-bottom: 0;
  min-height: 4rem;
  min-width: 4rem;
  &:hover,
  &:focus {
    box-shadow: none;
    color: #ffffff;
  }
}

a,
button,
.button,
input,
textarea,
select {
  &:focus,
  &:hover:focus {
    outline: none; //solid .12rem $focusColour;
    outline-offset: -.12rem;
  }
}

a:hover:focus {
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="color"],
textarea,
select {
  width: 100%;
  border: 1px solid $captionColour;
  &:hover {
    border-color: $bodyColour;
  }
}

select {
  background: $codeBackgroundColour;
}

textarea {
    resize: vertical;
}

label {
  @include baseline($fontsize: zeta, $font: $bodytype, $lineheight: 2, $below: 2, $breakpoint: all);
}

code {
  padding: .12rem .2rem;
  color: $headingColour;
}

pre code {
  padding: 0;
}

.required {
  color: red;
}

::-webkit-input-placeholder {
  opacity: 0.5;
}
::-moz-placeholder {
  opacity: 0.5;
}
:-ms-input-placeholder {
  opacity: 0.5;
}
:-moz-placeholder {
  opacity: 0.5;
}

::selection {
  background: $headingColour;
  color: $backgroundColour;
  text-shadow: none;
}


// Sassline overrides
.typeset {
  a > code {
    text-shadow: none;
  }
  .button,
  button {
    background-image: none;
    text-shadow: none;
    color: $backgroundColour;
    &:hover,
    &:active,
    &:focus {
      background-image: none;
      color: $backgroundColour;
    }
  }
  hr {
    width: 100%;
  }
  li {
    > p {
      padding: 0;
      margin: 0;
    }
  }
  .nav a {
    padding-left: 0;
    padding-right: 0;
    margin-left: .2rem;
    margin-right: .2rem;
  }
  pre {
    white-space: pre;
    overflow-x: scroll;
  }
}
